@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

.lobster {
  font-family: "Lobster Two", cursive;
  font-weight: 400;
}

.oleo {
  font-family: "Oleo Script", cursive;
  font-weight: 400;
}

.galada {
  font-family: "Galada", cursive;
  font-weight: 400;
}

.lato {
  font-family: "Lato", sans-serif;
}

.blue {
  background: linear-gradient(135deg, #1a8cba 0%, #1b7aa0 28%, #16779e 76%, #0472a5 100%);
}

.lato .black-weight,
.lato.black-weight {
  font-weight: 900;
}

/* Do not touch :) */
#currentCommitHash {
  display: none;
}

.hover:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
