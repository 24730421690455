@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
.cardContainer {
  position: relative;
  /* width: 460px; */
  /* height: 660px; */
  margin: 4px;
  perspective: 1000px;
}

.active {
  transform: translateZ(0px) rotateY(180deg) !important;
}

.active:after {
  display: none;
}

.card {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -moz-backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translateZ(0px);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  opacity: 1;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1.4);
}

.card:hover {
  transform: translateZ(0px);
}

.card:hover:after {
  opacity: 1;
}

.card .side {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
}

.card .front {
  z-index: 2;
}

.card .back {
  transform: rotateY(180deg);
}

.side {
  position: relative;
}

/* */
.card .front .layer {
  width: 640px;
  height: 400px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.card .front .logo {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 80px;
}

.card .front {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .front h1 {
  font-size: 64px;
  color: white;
  overflow: hidden;
  z-index: 100;
  padding: 0 36px;
  text-align: center;

  /* Lato Related */
  font-weight: 900;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4), 0px 2px 3px rgba(166, 10, 10, 0.2);
}

.card .back {
  text-align: center;
}

.card .back h1 {
  font-size: 28px;

  /* Lato Related */
  font-weight: 900;
}

.card .back p {
  font-size: 20px;
}

.card .back p.wishes {
  margin: 20px 0;
}

.card .back p.signature {
  text-align: right;

  /* Lato Related */
  font-weight: 700;
}

.lobster {
  font-family: "Lobster Two", cursive;
  font-weight: 400;
}

.oleo {
  font-family: "Oleo Script", cursive;
  font-weight: 400;
}

.galada {
  font-family: "Galada", cursive;
  font-weight: 400;
}

.lato {
  font-family: "Lato", sans-serif;
}

.blue {
  background: linear-gradient(135deg, #1a8cba 0%, #1b7aa0 28%, #16779e 76%, #0472a5 100%);
}

.lato .black-weight,
.lato.black-weight {
  font-weight: 900;
}

/* Do not touch :) */
#currentCommitHash {
  display: none;
}

.hover:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

